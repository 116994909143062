import axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios';

const c3apiv2: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL + '/v2',
  timeout: 10000,
});

c3apiv2.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('token');
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);

c3apiv2.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);

export default c3apiv2;
